import { Link as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import BubbleChart from '@mui/icons-material/BubbleChart';

export const Logo = () => <Link to="/" component={RouterLink} underline="none" color="inherit">
    <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <BubbleChart fontSize="large"/>
        <Box sx={{ ml: 1 }}>Basecamp Admin</Box>
    </Box>
</Link>;