import snakeCase from 'snakecase-keys';
import camelCase from 'camelcase-keys';

export const request = async (getToken, url, { body, ...params } = {}) => {
    const token = await getToken();
    const snakeCaseBody = body && snakeCase(body, { deep: true });

    console.log('Request:', url, {...params, body: snakeCaseBody});

    const res = await fetch(url, {
        ...params,
        ...(snakeCaseBody ? { body: JSON.stringify(snakeCaseBody) } : {}),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    const data = await res.json();
    const camelcaseData = camelCase(data, { deep: true });

    console.log('Response: ', url, camelcaseData);

    return camelcaseData;
}