import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import BubbleChart from '@mui/icons-material/BubbleChart';

import { ResourceWithStatus } from '../layouts/ResourceWithStatus';

const Logo = () => <Link to="/" component={RouterLink} underline="none" color="inherit">
    <Box sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
        <BubbleChart fontSize="large"/>
        <Box sx={{ ml: 1 }}>SKS Feedback</Box>
    </Box>
</Link>;

const anonymousAnimals = [
    'Lion 🦁',
    'Fox 🦊',
    'Unicorn 🦄',
    'Dog 🐶',
    'Panda 🐼',
    'Eagle 🦅',
    'Parrot 🦜',
    'Bear 🐻',
    'Fish 🐟',
    'Cat 🐱',
    'Wolf 🐺',
    'Dolphin 🐬',
    'Rabbit 🐰',
    'Tiger 🐅',
    'Flamingo 🦩',
    'Mouse 🐭'
];

const formatTopicResponse = (responses, respondent, response) => {
    if (response) {
        const respondentResponses = responses?.[respondent] || [];
        return {
            ...responses,
            [respondent]: [...respondentResponses, response]
        }
    } else {
        return responses
    }
}

const formatResponse = ({ start, keep, stop, words, skills, activities, anonymous }, respondent, response) => {
    let formattedRespondent = respondent;
    if (respondent === 'Anonymous') {
        const index = start ? Object.keys(start).length : 0;
        formattedRespondent = `${formattedRespondent} ${anonymousAnimals[index] || index + 1}`;
    }
    return {
        'start': formatTopicResponse(start, formattedRespondent, response.start),
        'keep': formatTopicResponse(keep, formattedRespondent, response.keep),
        'stop': formatTopicResponse(stop, formattedRespondent, response.stop),
        'words': formatTopicResponse(words, formattedRespondent, response.words),
        'skills': formatTopicResponse(skills, formattedRespondent, response.skills),
        'activities': formatTopicResponse(activities, formattedRespondent, response.activities),
    }
}

const responseReducer = (acc, item) => {
    const responses = acc[item.requesterEmail] || {};
    if (item.response) {
        const { anonymous } = item.response || {};
        const respondent = anonymous ? 'Anonymous' : `${item.name} (${item.email})`;
        return {
            ...acc,
            [item.requesterEmail]: formatResponse(responses, respondent, item.response),
        }
    }
    return acc;
}

const formatResponses = values => values.reduce(responseReducer, {});

const TopicCard = ({ divider = true, responses, question }) => (
    responses ? (
        <>
            {divider ? <Divider sx={{ mt: 1 }}/> : null}
            <Box>
                <Typography sx={{ fontSize: 16, mt: 1 }}>{question}</Typography>
                {Object.keys(responses).map((respondent) => (
                    <Box key={respondent}>
                        <Typography sx={{ fontSize: 14, pl: 2 }}><b>{respondent}</b> wrote:</Typography>
                        {responses[respondent].map((response, index) => (
                            <Typography
                                key={index}
                                sx={{ fontSize: 12, pl: 4, whiteSpace: 'pre-line', wordBreak: 'break-all' }}
                            >
                                {response}
                            </Typography>
                        ))}
                    </Box>
                ))}
            </Box>
        </>
    ) : null
);

const FooterComp = ({ className }) => (
    <Typography className={className} sx={{ my: 5 }} fontWeight={400} variant="h6" align="center">
        Enjoy your Basecamp :)
    </Typography>
)

const Footer = styled(FooterComp)`
  display: none;
  @media print {
    display: block;
  }
`;

const ResponseCardComp = ({ className, email, responses: { start, keep, stop, words, skills, activities } }) => {
    return (
        <Box className={className} sx={{ mb: 5 }}>
            <Typography sx={{ mb: 5 }} fontWeight={400} variant="h6" align="center">
                <b><Logo/></b>
            </Typography>
            <Typography sx={{ my: 5 }} fontWeight={400} variant="h6" align="center">
                Basecamp Feedback for <b>{email}</b>
            </Typography>
            <TopicCard
                divider={false}
                question={<>What should I <b>stop</b> doing?</>}
                responses={stop}
            />
            <TopicCard
                question={<>What should I <b>keep</b> doing?</>}
                responses={keep}
            />
            <TopicCard
                question={<>What should I <b>start</b> doing?</>}
                responses={start}
            />
            <TopicCard
                question="What words and/or phrases describe me?"
                responses={words}
            />
            <TopicCard
                question="What skills and/or knowledge should I improve or learn?"
                responses={skills}
            />
            <TopicCard
                question="What activities should I spend more/less time on?"
                responses={activities}
            />
            <Footer/>
        </Box>
    )
}

const ResponseCard = styled(ResponseCardComp)`
  @media print {
    page-break-before: always;
  }
`;

export const SKSResponsesPage = () => {
    const { id } = useParams();

    const render = ({ resource }) => {
        if (!resource) return null;

        const responses = formatResponses(resource);
        const responsesCards = Object.keys(responses).sort().map((email, index) => (
            <ResponseCard key={email} email={email} responses={responses[email]}/>
        ));
        return <>{responsesCards}</>
    }

    return <ResourceWithStatus render={render} useResourceArgs={[`basecamp/${id}/sks-responses`]}/>
}





