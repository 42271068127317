import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SaveIcon from '@mui/icons-material/Save';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import { invitationEmailTemplate, reminderEmailTemplate } from './emailTemplates';

const MAX_INVITES_PER_SURVEY = 30
const timezones = moment.tz.names();

const formatDateAPI = (date, timezone) => (
    moment(date).tz(timezone, true).format('YYYY-MM-DD HH:mm:00Z')
);

const formatDateInput = (date, timezone) => (
    timezone ?
        moment(date).tz(timezone).format('YYYY-MM-DDTHH:mm') :
        moment(date).format('YYYY-MM-DDTHH:mm')
);

const parseDefaultValues = values => {
    if (values) {
        return {
            ...values,
            startDate: formatDateInput(values.startDate, values.timezone),
            responsesVisibilityDatetime: formatDateInput(values.responsesVisibilityDatetime, values.timezone),
            responsesAcceptanceDatetime: formatDateInput(values.responsesAcceptanceDatetime, values.timezone),
        }
    } else {
        return {
            invitationEmailTemplate,
            reminderEmailTemplate,
            maxInvitesPerSurvey: MAX_INVITES_PER_SURVEY,
            // name: 'Basecamp name',
            // timezone: 'America/New_York',
            // startDate: moment(new Date()).format('YYYY-MM-DDThh:mm'),
            // responsesVisibilityDatetime: moment(new Date()).format('YYYY-MM-DDThh:mm'),
            // responsesAcceptanceDatetime: moment(new Date()).format('YYYY-MM-DDThh:mm'),
        }
    }
}

const PreviewBox = ({ children, previewHtml }) => {
    const [preview, setPreview] = useState(false);

    return (
        <Box sx={{ position: 'relative', width: '100%', mb: 5 }}>
            <div style={{ display: preview ? 'none' : 'block' }}>
                {children}
            </div>
            {preview ? <Paper sx={{p: 3}}>
                <div dangerouslySetInnerHTML={{ __html: previewHtml }}/>
            </Paper> : null}
            <IconButton
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => setPreview(!preview)}
            >
                {preview ? <EditIcon/> : <PreviewIcon/>}
            </IconButton>
        </Box>
    )
}


// TODO: Email templates. Reset to default.
// TODO: Email templates. Variables, including the date variable. Date should be stolen from dates input.
export const BaseCampForm = ({ defaultValues, onSubmit }) => {
    const navigate = useNavigate();
    const formData = useForm({
        mode: 'onChange',
        defaultValues: parseDefaultValues(defaultValues),
    });
    const { register, handleSubmit, getValues, formState: { errors, isSubmitting, isDirty, isValid } } = formData;

    const onFormSubmit = async (args) => {
        const {
            timezone,
            startDate,
            responsesVisibilityDatetime,
            responsesAcceptanceDatetime,
            maxInvitesPerSurvey,
            ...values
        } = args;
        await onSubmit({
            timezone,
            ...values,
            maxInvitesPerSurvey: parseInt(maxInvitesPerSurvey, 10),
            startDate: formatDateAPI(startDate, timezone),
            responsesVisibilityDatetime: formatDateAPI(responsesVisibilityDatetime, timezone),
            responsesAcceptanceDatetime: formatDateAPI(responsesAcceptanceDatetime, timezone),
        });
        navigate('/');
    };

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                    required
                    disabled={isSubmitting}
                    sx={{ mb: 5, width: '100%', maxWidth: 350 }}
                    InputLabelProps={{ shrink: true }}
                    label="Basecamp Name"
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                    {...register('name', { required: 'This field is required' })}
                />

                <TextField
                    required
                    disabled={isSubmitting}
                    sx={{ mb: 5, width: '100%', maxWidth: 350 }}
                    InputLabelProps={{ shrink: true }}
                    label="Max invites per survey"
                    error={Boolean(errors?.maxInvitesPerSurvey)}
                    helperText={errors?.maxInvitesPerSurvey?.message}
                    {...register('maxInvitesPerSurvey', { required: 'This field is required' })}
                />

                <TextField
                    required
                    inputProps={{ list: 'timezones' }}
                    disabled={isSubmitting}
                    InputLabelProps={{ shrink: true }}
                    sx={{ mb: 5, width: '100%', maxWidth: 350 }}
                    label="Timezone"
                    error={Boolean(errors?.timezone)}
                    helperText={errors?.timezone?.message}
                    {...register('timezone', {
                        required: 'This field is required',
                        validate: v => timezones.includes(v) || 'Timezone does not exists'
                    })}
                />
                <datalist id="timezones">
                    {timezones.map(i => <option key={i} value={i}/>)}
                </datalist>

                <Box sx={{ mb: 5, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <TextField
                        required
                        disabled={isSubmitting}
                        type="datetime-local"
                        sx={{ width: '100%', maxWidth: 350 }}
                        InputLabelProps={{ shrink: true }}
                        label="Date of Basecamp"
                        error={Boolean(errors?.startDate)}
                        helperText={errors?.startDate?.message}
                        inputProps={{ min: formatDateInput(new Date()) }}
                        {...register('startDate', { required: 'This field is required' })}
                    />

                    <TextField
                        required
                        disabled={isSubmitting}
                        type="datetime-local"
                        sx={{ width: '100%', maxWidth: 350 }}
                        InputLabelProps={{ shrink: true }}
                        label="Responses Visibility Date"
                        error={Boolean(errors?.responsesVisibilityDatetime)}
                        helperText={errors?.responsesVisibilityDatetime?.message}
                        inputProps={{ min: formatDateInput(new Date()) }}
                        {...register('responsesVisibilityDatetime', { required: 'This field is required' })}
                    />

                    <TextField
                        required
                        disabled={isSubmitting}
                        type="datetime-local"
                        sx={{ width: '100%', maxWidth: 350 }}
                        InputLabelProps={{ shrink: true }}
                        label="Responses Acceptance Date"
                        error={Boolean(errors?.responsesAcceptanceDatetime)}
                        helperText={errors?.responsesAcceptanceDatetime?.message}
                        inputProps={{ min: formatDateInput(new Date()) }}
                        {...register('responsesAcceptanceDatetime', { required: 'This field is required' })}
                    />
                </Box>

                <PreviewBox previewHtml={getValues().invitationEmailTemplate}>
                    <TextField
                        required
                        fullWidth
                        multiline
                        disabled={isSubmitting}
                        minRows={5}
                        InputLabelProps={{ shrink: true }}
                        label="Invitation email template"
                        error={Boolean(errors?.invitationEmailTemplate)}
                        helperText={errors?.invitationEmailTemplate?.message || 'Available variables: from_email, to_email, survey_link'}
                        {...register('invitationEmailTemplate', { required: 'This field is required' })}
                    />
                </PreviewBox>

                <PreviewBox previewHtml={getValues().reminderEmailTemplate}>
                    <TextField
                        required
                        fullWidth
                        multiline
                        disabled={isSubmitting}
                        minRows={5}
                        InputLabelProps={{ shrink: true }}
                        label="Reminder email template"
                        error={Boolean(errors?.reminderEmailTemplate)}
                        helperText={errors?.reminderEmailTemplate?.message || 'Available variables: from_email, to_email, survey_link'}
                        {...register('reminderEmailTemplate', { required: 'This field is required' })}
                    />
                </PreviewBox>

                <Button
                    type="submit"
                    endIcon={isSubmitting ? <CircularProgress size={18} color="inherit"/> : <SaveIcon/>}
                    variant="contained"
                    sx={{ p: 1, px: 5 }}
                    disabled={isSubmitting || !isDirty || !isValid}
                >
                    Save
                </Button>
            </Box>
        </form>
    )
};