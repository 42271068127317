import { Link } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export const PlusFabButton = ({ to }) => {
    const theme = useTheme();

    const fabStyle = {
        position: 'fixed',
        bottom: 16,
        right: 16,
    };

    return (
        <Zoom
            in
            timeout={300}
            style={{ transitionDelay: theme.transitions.duration.enteringScreen }}
            unmountOnExit
        >
            <Fab color="primary" aria-label="add" sx={fabStyle} component={Link} to={to}>
                <AddIcon/>
            </Fab>
        </Zoom>
    )
}