import { useResource } from '../hooks/useResource';
import { ProgressBar } from '../components/NavBar';
import { StatusMessage } from './DefaultLayout';

export const ResourceWithStatus = ({ render, useResourceArgs }) => {
    const resourceData = useResource(...useResourceArgs);
    const { isFetching, error } = resourceData;

    return <>
        <ProgressBar show={isFetching}/>
        <StatusMessage message={error}/>
        {render(resourceData)}
    </>
}