import { createRef } from 'react';
import { SnackbarProvider } from 'notistack';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export const NotificationsProvider = ({ children, ...props }) => {
    const snackBarRef = createRef();
    const onClickDismiss = key => () => snackBarRef.current.closeSnackbar(key);

    return (
        <SnackbarProvider
            ref={snackBarRef}
            action={(key) => (
                <IconButton color="default" onClick={onClickDismiss(key)} aria-label="close">
                    <CloseIcon/>
                </IconButton>
            )}
            {...props}
        >
            {children}
        </SnackbarProvider>
    )
};