// TODO: Insert dates from Basecamp
export const invitationEmailTemplate = `<p>
We're sending the message below on behalf of {from_email} at {to_email}:
</p>
<p>
Hello. 
</p>
<p>
I am taking part in an event on DATE called Basecamp where I will be working on myself to engineer my future and achieve my goals.
<br>
In order to help me through this process, I am asking for feedback from key people in my life who can help give insight into my habits, behaviors, skills, and attributes.
</p>
<p>
If you’re willing to help me on this journey, can you please complete <a href="{survey_link}">this form</a> by <b>DATE</b>?
<br>
You have the option to fill out the form anonymously. Only a user registered with {to_email} can provide feedback.
<br>
<br>
Many thanks.
</p>`;

// TODO: Insert dates from Basecamp
export const reminderEmailTemplate = `<p>
Hello.
</p>
<p>
This is a friendly reminder to fill out the Basecamp Participant Feedback survey for {from_email}
<br>
The link to the form is <a href="{survey_link}">here</a>
<br>
Only a user registered with {to_email} can provide feedback.
<br>
This form closes on DATE.
</p>`;