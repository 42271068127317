import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';

import { PlusFabButton } from '../components/PlusFabButton';
import { LinkButton } from '../components/LinkButton';
import { formatDate } from '../helpers/utils';
import { ResourceWithStatus } from '../layouts/ResourceWithStatus';
import { CONSTANTS } from '../utils/constants';

export const BaseCampsPage = () => {
    const render = ({ resource, remove, isRemoving }) => (
        <>
            {resource?.map((b) => (
                <Card key={b.basecampId} sx={{ mb: 3 }}>
                    <CardContent sx={{ pb: 0 }}>
                        <Typography>{b.name}</Typography>
                        <Typography color="text.secondary" sx={{ fontSize: 12 }}>
                            {formatDate(b.startDate, b.timezone)}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <LinkButton to={`basecamp/${b.basecampId}`}>Configure</LinkButton>
                        <LinkButton to={`basecamp/${b.basecampId}/participants`}>Manage participants</LinkButton>
                        {/*TODO: It doesn't work for now*/}
                        <LinkButton to={`basecamp/${b.basecampId}/sks-responses`}>SKS Responses</LinkButton>
                        {CONSTANTS.ENV !== 'prod' ? (
                            <Button
                                sx={{ ml: 'auto' }}
                                disabled={isRemoving}
                                color="error"
                                onClick={() => remove(b.basecampId, 'basecampId')}
                            >
                                Remove
                            </Button>
                        ) : null}
                    </CardActions>
                </Card>
            ))}
            <PlusFabButton to="/basecamp"/>
        </>
    )

    return <ResourceWithStatus
        render={render}
        useResourceArgs={['basecamp']}
    />
}