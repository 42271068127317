import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import { DefaultLayout } from './layouts/DefaultLayout';
import { BaseCampsPage } from './pages/BaseCampsPage';
import { EditBaseCampPage } from './pages/EditBaseCampPage';
import { CreateBaseCampPage } from './pages/CreateBaseCampPage';
import { BaseCampParticipantsPage } from './pages/BaseCampParticipantsPage';
import { SKSResponsesPage } from './pages/SKSResponsesPage';
import { NotificationsProvider } from './components/NotificationsProvider';

import { CONSTANTS } from './utils/constants';

const MainLoading = () => {
    const style = {
        position: 'fixed',
        top: '45%',
        fontSize: '2rem',
        fontFamily: 'monospace',
        width: '100%',
        textAlign: 'center'
    };
    const loadingStyle = {
        fontSize: '1rem',
    }
    return <div style={style}>Basecamp Admin &copy; HOS<br/><span style={loadingStyle}>Loading...</span></div>
}


const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    return <Auth0Provider
        domain={CONSTANTS.AUTH_DOMAIN}
        clientId={CONSTANTS.AUTH_CLIENT_ID}
        redirectUri={CONSTANTS.AUTH_REDIRECT_URI}
        audience={CONSTANTS.AUTH_AUDIENCE}
        onRedirectCallback={appState => navigate(appState.returnTo)}
        scope={CONSTANTS.AUTH_SCOPE}
    >
        {children}
    </Auth0Provider>
}

const Pages = () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({
                appState: {
                    returnTo: window.location.pathname
                }
            });
        }
    }, [loginWithRedirect, isLoading, isAuthenticated])

    if (isLoading) {
        return <MainLoading />
    }

    return (
        <DefaultLayout>
            <Routes>
                <Route path="/" element={<BaseCampsPage/>}/>
                <Route path="basecamp" element={<CreateBaseCampPage/>}/>
                <Route path="basecamp/:id" element={<EditBaseCampPage/>}/>
                <Route path="basecamp/:id/participants" element={<BaseCampParticipantsPage/>}/>
                <Route path="basecamp/:id/sks-responses" element={<SKSResponsesPage/>}/>
            </Routes>
        </DefaultLayout>
    )
}

export const App = () => {
    return (
        <NotificationsProvider maxSnack={3}>
            <React.StrictMode>
                <Router>
                    <AuthProvider>
                        <Pages/>
                    </AuthProvider>
                </Router>
            </React.StrictMode>
        </NotificationsProvider>
    )
};
