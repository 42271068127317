import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { request } from '../utils/utils';
import { CONSTANTS } from '../utils/constants';

//TODO: Loading state
//TODO: Not found state
//TODO: Error state
export const useResource = (resourceRoute, preventAutoFetching = false) => {
    const { getAccessTokenSilently } = useAuth0();

    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(!preventAutoFetching);
    const [isRemoving, setIsRemoving] = useState(false);
    const [resource, setResource] = useState(null);

    const readResource = useCallback(async () => {
        setIsFetching(true)
        try {
            const { data } = await request(getAccessTokenSilently, `${CONSTANTS.API_DOMAIN}${resourceRoute}`);
            setResource(data);
            setIsFetching(false);
            setError(null);
        } catch (e) {
            setError(e.message);
            setIsFetching(false);
        }
    }, [getAccessTokenSilently, resourceRoute]);


    const createResource = useCallback(async (body) => {
        const { data } = await request(
            getAccessTokenSilently,
            `${CONSTANTS.API_DOMAIN}${resourceRoute}`,
            { body, method: 'POST' }
        );
        if (resource && Array.isArray(resource)) {
            setResource([data, ...resource]);
        } else {
            setResource(data);
        }

    }, [getAccessTokenSilently, resourceRoute, resource]);


    const updateResource = useCallback(async (body) => {
        const { data } = await request(
            getAccessTokenSilently,
            `${CONSTANTS.API_DOMAIN}${resourceRoute}`,
            { body, method: 'PATCH' }
        );
        setResource(data);
    }, [getAccessTokenSilently, resourceRoute])


    const deleteResource = useCallback(async (id, idField) => {
        setIsRemoving(true);
        try {
            await request(
                getAccessTokenSilently,
                `${CONSTANTS.API_DOMAIN}${resourceRoute}${id ? '/' + id : ''}`,
                { method: 'DELETE' }
            );
            setResource(resource.filter(r => r[idField] !== id));
            setIsRemoving(false);
        } catch (e) {
            // TODO: Notification?
            setIsRemoving(false);
        }
    }, [getAccessTokenSilently, resourceRoute, resource])


    useEffect(() => {
        if (!preventAutoFetching) {
            readResource();
        }
    }, [resourceRoute, preventAutoFetching, readResource]);


    return {
        resource,
        isFetching,
        isRemoving,
        error,
        read: readResource,
        create: createResource,
        update: updateResource,
        remove: deleteResource
    };
};