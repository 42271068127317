import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import PersonAdd from '@mui/icons-material/PersonAdd';
import TextField from '@mui/material/TextField';

import { emailPattern } from '../../helpers/utils';

export const BaseCampParticipantForm = ({ defaultValues, validate, onSubmit }) => {
    const formData = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
    });
    const { register, handleSubmit, reset, formState: { errors, isSubmitting, isDirty, isValid } } = formData;

    const onFormSubmit = async (values) => {
        await onSubmit(values);
        reset();
    };

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'start', mb: 3 }}>
                <TextField
                    required
                    type="email"
                    disabled={isSubmitting}
                    sx={{ width: '100%', maxWidth: 350 }}
                    InputLabelProps={{ shrink: true }}
                    label="Participant Email"
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message || ' '}
                    {...register('email', {
                        required: 'This field is required',
                        pattern: { value: emailPattern, message: 'Email is invalid' },
                        validate: validate
                    })}
                />

                <Button
                    type="submit"
                    endIcon={isSubmitting ? <CircularProgress size={18} color="inherit"/> : <PersonAdd/>}
                    variant="contained"
                    sx={{ p: 2, px: 3, ml: 3 }}
                    disabled={isSubmitting || !isDirty || !isValid}
                >
                    Add
                </Button>
            </Box>
        </form>
    )
};