import ReactDOM from 'react-dom';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { NavBar } from '../components/NavBar';
import { Footer } from '../components/Footer';

export const StatusMessage = ({ message }) => {
    const el = document.getElementById('status-message');
    if (el && message) {
        const comp = <Typography component="h1" variant="h6" align="center" sx={{ mt: 5, p: 2 }}>{message}</Typography>
        return ReactDOM.createPortal(comp, el);
    }
    return null;
}

export const DefaultLayout = ({ children }) => (
    <>
        <NavBar/>
        <Box sx={{ width: '100%' }} id="status-message" />
        <Container maxWidth="lg" sx={{ flexGrow: 1, py: 5 }}>
            {children}
        </Container>
        <Footer/>
    </>
);