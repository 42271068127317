import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { ResourceWithStatus } from '../layouts/ResourceWithStatus';
import Button from '@mui/material/Button';
import PersonRemove from '@mui/icons-material/PersonRemove';
import { BaseCampParticipantForm } from '../components/forms/BaseCampParticipantForm';

export const AddBaseCampParticipantComp = ({ participants, create }) => {
    const validate = useCallback((value) => (
        participants.find(({ participantEmail }) => participantEmail === value) ? 'User with this email is already invited.' : true
    ), [participants]);

    return <BaseCampParticipantForm onSubmit={create} validate={validate}/>
}


export const BaseCampParticipantsPage = () => {
    const { id } = useParams();

    const renderParticipants = ({ resource, isRemoving, remove }) => resource?.map(({ participantEmail }) => (
        <Paper key={participantEmail} sx={{ mb: 1, p: 1, px: 2, display: 'flex', alignItems: 'center' }}>
            <Typography>{participantEmail}</Typography>
            <Button
                sx={{ ml: 'auto' }}
                disabled={isRemoving}
                color="error"
                onClick={() => remove(participantEmail, 'participantEmail')}
                endIcon={<PersonRemove />}
            >
                Remove
            </Button>
        </Paper>
    ));

    const renderPage = (props) => <>
        <AddBaseCampParticipantComp participants={props.resource} create={props.create}/>
        {renderParticipants(props)}
    </>

    return <ResourceWithStatus
        render={renderPage}
        useResourceArgs={[`basecamp/${id}/participants`]}
    />
};