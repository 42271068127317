import ReactDOM from 'react-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import LinearProgress from '@mui/material/LinearProgress';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { Logo } from './Logo';

export const ProgressBar = ({ show }) => {
    const el = document.getElementById('progress-bar');
    if (el && show) {
        return ReactDOM.createPortal(<LinearProgress/>, el);
    }
    return null;
}

const NavBarComp = ({ className }) => {
    const { logout } = useAuth0();

    return (
        <>
            <AppBar className={className} position="static" sx={{ bgcolor: 'text.primary' }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ mr: 4 }}>
                        <Logo/>
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}/>
                    <Button
                        onClick={() => logout({ returnTo: window.location.origin })}
                        color="inherit"
                    >
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ width: '100%' }} id="progress-bar" />
        </>
    )
}

export const NavBar = styled(NavBarComp)`
  @media print {
    display: none;
  }
`;