import { useParams } from 'react-router-dom';

import { BaseCampForm } from '../components/forms/BaseCampForm';
import { ResourceWithStatus } from '../layouts/ResourceWithStatus';

export const EditBaseCampPage = () => {
    const { id } = useParams();

    const render = ({resource, update}) => resource ? <BaseCampForm defaultValues={resource} onSubmit={update}/> : null;

    return <ResourceWithStatus render={render} useResourceArgs={[`basecamp/${id}`]}/>
};